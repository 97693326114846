<template>
  <div ref="top-div" class="az-faqs--filters-az">
    <div class="az-faqs--filters-az-container">
      <div v-for="(filter, index) in filters" :key="`az-faqs-${index}`" class="az-faqs--filters-az-cell">
        <a
          :id="filter.key"
          :class="{ activeViewAll: activeFilter == filter.key }"
          :title="filter.key.toUpperCase()"
          :href="`#${filter.key}`"
          class="az-faqs--filters-az-item"
          @click.prevent="handleSearch(filter.key)"
        >
          {{ filter.key === '0' ? '#' : filter.key.toUpperCase() }}
        </a>
      </div>
    </div>
    <div v-if="showResults" class="az-faqs--results-container">
      <ul>
        <li v-for="(result, index) in products" :key="`az-faqs-result-${index}`" class="az-faqs--results-cell" @click.prevent="handleShowMagazine(result)">
          {{ result.sku }}
        </li>
      </ul>
    </div>
    <table v-else-if="showMagazine && currentMagazine" class="az-faqs--magazine-container">
      <tr>
        <td>
          <TheMagazineImage :item="currentMagazine" :show-save-overlay="false" :show-free-gift-overlay="false" class="az-faqs--magazine-image" />
        </td>
        <td>
          <ul class="az-faqs--magazine-publisher-info">
            <li>
              <h2>{{ currentMagazine.title }}</h2>
            </li>
            <li>
              Publisher name:
              <p>
                <b>{{ currentMagazine.publisher.legalName }}</b>
              </p>
            </li>
            <li>
              Publisher contact number:
              <p>
                <a class="tel" :href="`tel:${currentMagazine.publisher.phone}`">
                  <b>{{ currentMagazine.publisher.phone }}</b>
                </a>
              </p>
            </li>
            <li>
              Publisher email address:
              <p>
                <a class="mailto" :href="`mailto:${currentMagazine.publisher.email}`">
                  <b>{{ currentMagazine.publisher.email }}</b>
                </a>
              </p>
            </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { azureViewAllSearch, createAzureSearchQuery, getOrderbyAzureSearchOption } from '~/lib/util/azuresearch'

export default {
  name: 'TheAZFAQsFilter',
  components: {
    TheMagazineImage: () => import('~/components/common/TheMagazineImage')
  },
  props: {
    activeHash: {
      type: String,
      required: false,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      currentMagazine: undefined,
      activeFilter: undefined,
      showResults: false,
      showMagazine: false,
      filters: [
        { key: '0' },
        { key: 'a' },
        { key: 'b' },
        { key: 'c' },
        { key: 'd' },
        { key: 'e' },
        { key: 'f' },
        { key: 'g' },
        { key: 'h' },
        { key: 'i' },
        { key: 'j' },
        { key: 'k' },
        { key: 'l' },
        { key: 'm' },
        { key: 'n' },
        { key: 'o' },
        { key: 'p' },
        { key: 'q' },
        { key: 'r' },
        { key: 's' },
        { key: 't' },
        { key: 'u' },
        { key: 'v' },
        { key: 'w' },
        { key: 'x' },
        { key: 'y' },
        { key: 'z' }
      ]
    }
  },

  fetchOnServer: true,
  computed: {
    ...mapState('search', ['products', 'query', 'count']),
    ...mapGetters({
      products: 'search/products',
      query: 'search/query',
      count: 'search/count'
    })
  },
  mounted() {
    const useCache = this.$store.getters['UI/useCache']
    console.log('QS useCache=', useCache)
    console.log('props.activeHash=', this.$props.activeHash)

    if (this.$props?.activeHash) {
      this.activeFilter = this.$props.activeHash
      this.handleSearch(this.activeFilter)
    }
  },

  methods: {
    async handleSearch(filter) {
      if (filter) {
        this.activeFilter = filter
        // Get the orderby search option
        const query = {
          t: filter,
          name: 'asc'
        }

        const orderby = getOrderbyAzureSearchOption({ query, useFirstDigit: true })
        this.$store.commit('search/setQuery', createAzureSearchQuery({ query }))

        await azureViewAllSearch(query && query.t.trim(), orderby, (error, data) => {
          if (data) {
            this.$store.commit('search/setProducts', data.value)
            this.$store.commit('search/setCount', data['@odata.count'])
            this.showResults = true
            this.showMagazine = false
            this.currentMagazine = undefined
            console.log(data)
            console.log(`COUNT=${data['@odata.count']}`)
          } else if (error) {
            console.error(error)
            this.showResults = false
            this.showMagazine = false
            this.currentMagazine = undefined
            this.$store.commit('search/setProducts', [])
          }
        })
      }
    },

    handleShowMagazine(magazine) {
      if (magazine) {
        this.showResults = false
        this.showMagazine = true
        this.currentMagazine = magazine
        this.activeFilter = undefined

        // Scroll to the top div
        this.$refs['top-div'] && this.$refs['top-div'].scrollIntoView({ behavior: 'instant' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
a.activeViewAll {
  color: #333;
  display: flex; /* For IE11/ MS Edge bug */
  pointer-events: none;
  text-decoration: none;

  background-color: #ddd;
  color: #fff;
  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    background-color: #ddd;
    color: #333;
  }
}

.az-faqs {
  &--title {
    margin: 1em 0;
    text-align: center;
    font-size: 28px;
  }
  &--top {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px 10px 20px;
    align-items: center;
  }
  &--filters-az-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
  }
  &--filters-az {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;

    &-cell {
      background-color: white;
      flex: 0 0 34px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin: 5px;
      box-sizing: border-box;
      &:before {
        content: '';
        display: table;
        padding-top: 100%;
      }
    }
    &-item {
      flex-grow: 1;
      border: 1px solid $navLinkColor;
      color: $navLinkColor;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:visited,
      &:active,
      &:focus {
        text-decoration: none;
        &:not(.activeViewAll) {
          background-color: #ddd;
          color: #000;
        }
      }
    }
  }
  &--results-container {
    margin: 10px;
  }
  &--results-cell {
    width: 100%;
    font-size: 16px;
    display: block;
    color: #333;
    padding: 10px 20px;
    border: 1px solid #ccc;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      border: 1px solid #333;
      // background: #ff0d00;
    }
    &:last-child {
      margin-bottom: unset;
    }
  }
  &--magazine-container {
    margin: 10px;
    h2 {
      font-size: 21px;
    }
    td {
      border: none;
    }
  }
  &--magazine-image {
    float: left;
    min-width: 135px;
    max-width: 135px;
  }
  &--magazine-publisher-info {
    li {
      background-color: #fff !important;
      display: grid;
      word-break: break-all;
      .mailto,
      .tel {
        text-decoration: none !important;
        color: #212529 !important;
        &:hover,
        &active,
        &focus {
          text-decoration: none !important;
          text-decoration: #212529 !important;
        }
      }
      h2 {
        word-break: auto-phrase !important;
      }
    }
  }
}
@media (min-width: 1025px) {
  .az-faqs {
    &--filters-az {
      align-items: center;
      &-cell {
        flex: 0 0 28px;
      }
    }
    &--filters-az-container {
      width: 100%;
    }
    &--magazine-container {
      margin: 20px;
      max-width: 600px;
    }
    &--magazine-image {
      min-width: 200px;
      max-width: 200px;
    }
    &--magazine-publisher-info {
      li {
        display: block;
      }
    }
  }
}
</style>
