<template>
  <section>
    <div class="hwbtn" aria-label="Open chat" @click="chatbotInitOrToggleWindowActive">
      <span class="pmimg hwico" aria-hidden="true"></span>
      <span class="help-text" :class="{ activetxt: activetxt }"><span id="HelpWidget_Label1">Chat</span></span>
    </div>

    <div class="hwchatwindow shadow10" :class="{ hwchatwindowactive: hwchatwindowactive }" aria-live="polite">
      <div class="hwchatheader">
        <div class="hwdialogclose" aria-label="Close chat" @click="toggleWindowActive">X</div>
        <div class="hwdialogreset" aria-label="Reset chat" @click="resetChat">
          <svg fill="#ffffff" width="20px" height="20px" class="reset-icon" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <div class="hwheaderpic" aria-hidden="true"></div>
        <div class="hwheadertxt"><span id="HelpWidget_Label2">Magazine.co.uk Support</span></div>
      </div>
      <div class="hwchatcontent">
        <div v-if="!showOpenTicketForm && !showTicketSentWindow" ref="chatMessagesContainer" class="hwchat" role="log" aria-live="polite">
          <div v-if="showChatMessagesLoadingSpinner" class="loading-indicator spinner"></div>

          <div
            v-for="(message, index) in chatMessages.slice(1)"
            :key="`HelpWidgetMessage#${index}`"
            :class="{ chatUser: message.messageType === 0, chatAssistant: message.messageType === 1, chatError: message.isError === true }"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="parseChatMessageResponse(message.text)"></p>
          </div>

          <div v-if="showSupportMessage && !showSendButtonSpinner" class="feedback-container">
            <div class="feedback-question">Need additional support via email?</div>
            <div class="feedback-options">
              <div class="feedback-button open-ticket" @click="toggleOpenTicketWindow(true)">Contact Support</div>
            </div>
          </div>

          <div v-if="showSendButtonSpinner" class="temp-box shimmer" aria-live="polite">
            <div class="dots">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </div>

        <div v-if="!showOpenTicketForm && !showTicketSentWindow" class="hwchattxtboxholder">
          <div class="hwchattxtboxsep">
            <input
              id="txtChat"
              ref="txtChat"
              v-model="txtChat"
              name="ctl00$HelpWidget$txtChat"
              type="text"
              class="hwchattxtbox"
              autocomplete="off"
              aria-label="Chat input"
              onkeydown="if (event.key === 'Enter') document.getElementById('btnChat').focus();"
              placeholder="Ask a question"
              :disabled="hasReachedTheMessageLimit === true"
            />
            <input
              v-if="!showSendButtonSpinner"
              id="btnChat"
              type="submit"
              name="ctl00$HelpWidget$btnChat"
              value="Send"
              class="hwchatsendbtn"
              aria-label="Send message"
              style="display: inline-block;"
              :disabled="txtChat?.trim() === '' || txtChat === undefined"
              @click="txtChat && chatMessageInput()"
            />
            <div v-else id="btnSpinner" class="hwchatsendbtn"><span class="spinner"></span></div>
          </div>
        </div>

        <div v-if="showOpenTicketForm" class="chatticketholder">
          <h4 class="mb10"><span id="HelpWidget_Label3">Contact Us</span></h4>
          <div class="mb20"><span id="HelpWidget_Label4">Use the form below, and we’ll get back to you as soon as possible.</span></div>

          <div class="mb10">
            <input
              id="txtUserEmailChatBot"
              v-model="openTicketForm.txtUserEmailChatBot"
              name="ctl00$HelpWidget$txtUserEmailChatBot"
              type="email"
              class="textboxlarge rad5 w100"
              aria-label="Your email address"
              placeholder="Please enter your email address here"
              minlength="5"
              maxlength="128"
            />
          </div>
          <div class="mb10">
            <input
              id="txtUserSubjectChatBot"
              v-model="openTicketForm.txtUserSubjectChatBot"
              name="ctl00$HelpWidget$txtUserSubjectChatBot"
              type="text"
              class="textboxlarge rad5 w100"
              aria-label="Query subject"
              placeholder="What does your query relate to?"
              minlength="1"
              maxlength="128"
            />
          </div>
          <div class="mb10">
            <textarea
              id="txtUserMessageChatBot"
              v-model="openTicketForm.txtUserMessageChatBot"
              name="ctl00$HelpWidget$txtUserMessageChatBot"
              rows="2"
              cols="20"
              class="textboxlarge rad5 w100 hwmessage"
              aria-label="Your message"
              placeholder="Enter your query here. Please supply as much detail as possible so we may serve you better."
              minlength="1"
              maxlength="256"
            ></textarea>
          </div>

          <table cellpadding="0" cellspacing="0" class="w100 mb20">
            <tr>
              <td style="width:35px; vertical-align: initial;">
                <span class="checkbox">
                  <input
                    id="chbSendMessageHistoryChatBot"
                    v-model="openTicketForm.checkboxSendCopyOfChatsChatBot"
                    type="checkbox"
                    name="ctl00$HelpWidget$chbSendMessageHistoryChatBot"
                    checked="checked"
                  />
                  <label for="chbSendMessageHistoryChatBot"> </label>
                </span>
              </td>
              <td>
                <span id="HelpWidget_Label5">Send a copy of chat messages</span>
              </td>
            </tr>
          </table>

          <div class="fr">
            <span
              v-if="!showOpenTicketSendButtonSpinner"
              id="SendTicketBtn"
              class="hwchatsendbtn"
              :class="{ 'hwchatsendbtn--disabled': isAValidForm === false }"
              @click.prevent="isAValidForm && openTicket()"
            >
              <span id="HelpWidget_Label6">Send</span>
            </span>
            <div v-else id="btnSpinnerTicket" class="hwchatsendbtn"><span class="spinner"></span></div>
          </div>
        </div>
        <div v-if="showTicketSentWindow" class="chatticketholdersent">
          <h4 class="mb10"><span id="HelpWidget_Label7">Message Sent</span></h4>
          <span id="HelpWidget_Label8">Many thanks for your enquiry.<br /><br />One of our support staff will get back to you soon.</span>
          <br />
          <span class="hwchatsendbtn hwtcbtn" @click="resetChat"><span id="HelpWidget_Label10">Start New Chat</span></span>
          <div class="mb20">
            <span class="hwchatsendbtn hwtcbtn" @click="resetChatAndCloseChat"><span id="HelpWidget_Label11">Close Chat</span></span>
          </div>
        </div>
        <!-- <div class="chatticketholdererror" style="display: none;">
          <h4 class="mb10"><span id="HelpWidget_Label12"></span><span id="HelpWidget_Label16">Error In sending Email</span></h4>
          <div class="mb20"><span id="HelpWidget_Label13"></span><span id="HelpWidget_Label17">We could not send your ticket at this time, please try again later</span></div>
          <div class="mb20">
            <span class="hwchatsendbtn hwtcbtn" @click="resetChat"><span id="HelpWidget_Label14">Start New Chat</span></span>
          </div>
          <div class="mb20">
            <span class="hwchatsendbtn hwtcbtn" @click="toggleWindowActive"><span id="HelpWidget_Label15">Close Chat</span></span>
          </div>
        </div> -->

        <div class="hwlogo">
          <div class="jmaglogo jmaglogoxs fr" aria-hidden="true"></div>
          <div class="fr hwfootertxt">
            <span id="HelpWidget_Label9">POWERED BY</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HelpWidget',
  components: {},
  props: {},
  data() {
    return {
      activetxt: false, // used to show the Chat text on the page load
      hwchatwindowactive: false, // used to show the Chat Message box
      showChatMessagesLoadingSpinner: false, // The Reload chat loading spinner
      showSendButtonSpinner: false, // The Send button oading spinner and the 3 dots one
      txtChat: undefined, // The chat question text value
      ErrorMessage: 'ERROR_MESSAGE',
      openTicketForm: {
        txtUserEmailChatBot: null, // The Chat Open Ticket Email
        txtUserSubjectChatBot: null, // The Chat Open Ticket Email Subject
        txtUserMessageChatBot: null, // The Chat Open Ticket Email Message
        checkboxSendCopyOfChatsChatBot: true // The Send copy of chat checkbox
      },
      showOpenTicketSendButtonSpinner: false
    }
  },

  computed: {
    ...mapGetters({
      id: 'HelpWidget/id',
      chatMessages: 'HelpWidget/chatMessages',
      page: 'HelpWidget/page',
      numberOfchatMessages: 'HelpWidget/numberOfchatMessages',
      showSupportMessage: 'HelpWidget/showSupportMessage',
      showOpenTicketForm: 'HelpWidget/showOpenTicketForm',
      showTicketSentWindow: 'HelpWidget/showTicketSentWindow',
      lastMessage: 'HelpWidget/lastMessage'
    }),
    hasReachedTheMessagesNumBeforeContactUs() {
      // If we reached the limit of the available chat, Init excluded
      return this.numberOfchatMessages >= this.$config.HelpWidgetMessagesBeforeContactUs
    },
    theHelpWidgetDidnTFindAnAnswer() {
      return this?.lastMessage?.text.startsWith(`I'm sorry, I'm having trouble answering this question`)
    },
    hasReachedTheMessageLimit() {
      // If we reached the limit of the available chat, Init excluded
      return this.numberOfchatMessages >= this.$config.HelpWidgetMessagesMaxNum
    },
    isAValidForm() {
      let isValid = true
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (emailPattern.test(this.openTicketForm.txtUserEmailChatBot) === false) {
        isValid = false
      }
      if (!this?.openTicketForm?.txtUserSubjectChatBot?.length || !this?.openTicketForm?.txtUserMessageChatBot?.length) {
        isValid = false
      }
      console.log(`isAValidForm=${isValid}`)
      return isValid
    }
  },

  mounted() {
    setTimeout(() => {
      this.activetxt = true
    }, 500) // Delay in milliseconds (adjust as needed)

    // First state of the Help Widget window
    this.hwchatwindowactive = this.$store.getters['HelpWidget/open']

    // Scroll the Message List to the bottom
    this.scrollToBottom()
  },
  methods: {
    async chatbotInitOrToggleWindowActive() {
      if (this.chatMessages.length === 1 && this.hwchatwindowactive === false) {
        // SHOW the Chat window
        this.toggleWindowActive()

        // SHOW the spinner
        this.showChatMessagesLoadingSpinner = true

        // Call the ChatBot API
        await this.callChatbot()

        // HIDE the spinner
        this.showChatMessagesLoadingSpinner = false
      } else {
        // SHOW/HIDE the Chat window
        this.toggleWindowActive()
      }
    },

    toggleWindowActive() {
      this.hwchatwindowactive = !this.hwchatwindowactive
      this.$store.commit('HelpWidget/open', this.hwchatwindowactive)

      // Scroll the Message List to the bottom
      this.scrollToBottom()
    },

    async chatMessageInput() {
      // SHOW the spinner
      this.showSendButtonSpinner = true

      // Add a new message on the Chat Message list
      await this.$store.commit('HelpWidget/addMessage', {
        text: this?.txtChat.trim(),
        messageType: 0
      })
      this.txtChat = undefined

      // Scroll the Message List to the bottom
      this.scrollToBottom()

      // Call the ChatBot API
      await this.callChatbot()

      // We reached the constrain to show the Support Message:

      // Use Case 1
      // The Help Widget didn't find an answer for the lastet question
      if (this.theHelpWidgetDidnTFindAnAnswer === true) {
        // Show 'Need additional support via email?' message
        await this.$store.commit('HelpWidget/showSupportMessage', true)

        // Scroll the Message List to the bottom
        this.scrollToBottom()
      }
      // Use Case 2
      // 1) At least that 2 response
      // 2) At least that 180 chars in the last response
      else if (this.hasReachedTheMessagesNumBeforeContactUs === true && this?.lastMessage?.text?.length >= this.$config.HelpWidgetMessagesBeforeContactUsMinChars) {
        // Show 'Need additional support via email?' message
        await this.$store.commit('HelpWidget/showSupportMessage', true)

        // Scroll the Message List to the bottom
        this.scrollToBottom()
      }

      // HIDE the spinner
      this.showSendButtonSpinner = false

      // Focus on the Question Input
      this.focusOnTheAskAQuestion()
    },

    scrollToBottom() {
      // Scroll the Message List to the bottom
      const container = this.$refs.chatMessagesContainer
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },

    focusOnTheAskAQuestion() {
      // Focus on the Question Input
      const container = this.$refs.txtChat
      if (container) {
        container.focus()
      }
    },

    resetChatAndCloseChat() {
      console.log('resetChatAndCloseChat')
      // Reset the Chat Messages and the Input Text
      this.$store.commit('HelpWidget/resetChatMessages', [])
      this.txtChat = undefined

      // Always HIDE the Help Widget box
      this.hwchatwindowactive = false
      this.$store.commit('HelpWidget/open', this.hwchatwindowactive)

      // Reset the Open Ticket Form data
      this.openTicketForm.txtUserEmailChatBot = null // The Chat Open Ticket Email
      this.openTicketForm.txtUserSubjectChatBot = null // The Chat Open Ticket Email Subject
      this.openTicketForm.txtUserMessageChatBot = null // The Chat Open Ticket Email Message
      this.openTicketForm.checkboxSendCopyOfChatsChatBot = true // The Send copy of chat checkbox

      // Purge All the windows' FLAGs
      this.$store.commit('HelpWidget/showSupportMessage', false)
      this.$store.commit('HelpWidget/showOpenTicketForm', false)
      this.$store.commit('HelpWidget/showTicketSentWindow', false)

      // HIDE the spinner
      this.showChatMessagesLoadingSpinner = false
      this.showOpenTicketSendButtonSpinner = false
    },

    async resetChat() {
      console.log('resetChat')
      // SHOW the spinner
      this.showChatMessagesLoadingSpinner = true

      // Reset Used Flags
      // HIDE the support Message on the bottom
      this.$store.commit('HelpWidget/showSupportMessage', false)
      // HIDE the Open Ticket Window
      this.$store.commit('HelpWidget/showOpenTicketForm', false)
      // HIDE Ticket Sent Window
      this.$store.commit('HelpWidget/showTicketSentWindow', false)

      // Reset the Open Ticket Form data
      this.openTicketForm.txtUserEmailChatBot = null // The Chat Open Ticket Email
      this.openTicketForm.txtUserSubjectChatBot = null // The Chat Open Ticket Email Subject
      this.openTicketForm.txtUserMessageChatBot = null // The Chat Open Ticket Email Message
      this.openTicketForm.checkboxSendCopyOfChatsChatBot = true // The Send copy of chat checkbox

      // Remove the Latest Hwlp Widget ID
      this.$store.commit('HelpWidget/id', undefined)

      // Reset the Chat Messages and the Input Text
      this.$store.commit('HelpWidget/resetChatMessages', [])
      this.txtChat = undefined

      // Always open the Help Widget box
      this.hwchatwindowactive = true
      this.$store.commit('HelpWidget/open', this.hwchatwindowactive)

      // Call the ChatBot API
      await this.callChatbot()

      // HIDE the spinner
      this.showChatMessagesLoadingSpinner = false
      this.showOpenTicketSendButtonSpinner = false

      // Focus on the Question Input
      this.focusOnTheAskAQuestion()
    },

    async callChatbot() {
      this.$axios.setHeader('Content-Type', 'application/application/json', ['post'])

      if (this.id === undefined) {
        // Create a new Help Widget Id
        this.$store.commit('HelpWidget/createIdandSavePage', this.$route.path)
        console.log(`NEW Help Widget ID=${this.id} page=${this.page}`)
      }

      // All the Chat Messages, all at once need to be sent to the ChatBot API
      const data = {
        chatMessages: this.chatMessages,
        id: this.id,
        email: this.$store.getters['s/email'] || undefined,
        page: this.page
      }

      console.log(`callChatbot API data=`, data)
      const url = '/HelpWidget'
      await this.$axios
        .$post(url, data)
        .then(res => {
          // Response Object
          // In the response prop you will have all the chat messages
          // const obj = {
          //   success: true,
          //   response: [
          //     {
          //       messageType: 0,
          //       text: 'Initialise'
          //     }
          //   ],
          //   error: undefined,
          //   statusCode: 200|404|500
          // }
          console.log('/HelpWidget API response=', res)
          this.$store.commit('HelpWidget/chatMessages', res.response)
        })
        .catch(error => {
          console.error('/HelpWidget API error=', error)

          // Add an Error Message
          this.$store.commit('HelpWidget/addMessage', {
            text: this.ErrorMessage,
            messageType: 1,
            isError: true
          })
        })

      // Scroll the Message List to the bottom
      this.scrollToBottom()
    },

    async openTicket() {
      if (this.isAValidForm === false) {
        console.error(`Not a valid form`)
      }
      this.$axios.setHeader('Content-Type', 'application/application/json', ['post'])

      // All the Chat Messages, all at once need to be sent to the ChatBot API
      const data = {
        openTicket: this.openTicketForm,
        chatMessages: this.openTicketForm?.checkboxSendCopyOfChatsChatBot === true ? this.chatMessages : undefined,
        id: this.id
      }

      console.log(`openTicket API data=`, data)
      const url = '/HelpWidget/openTicket'
      this.showOpenTicketSendButtonSpinner = true

      await this.$axios
        .$post(url, data)
        .then(res => {
          console.log('/HelpWidget/openTicket API response=', res)
        })
        .catch(error => {
          console.error('/HelpWidget/openTicket API error=', error)
        })

      setTimeout(() => {
        this.showOpenTicketSendButtonSpinner = false
        this.toggleOpenTicketWindow(false)
        this.toggleTicketSentWindow(true)
      }, 500) // Delay in milliseconds (adjust as needed)

      // Scroll the Message List to the bottom
      this.scrollToBottom()
    },

    toggleOpenTicketWindow(value) {
      this.$store.commit('HelpWidget/showOpenTicketForm', value)
    },
    toggleTicketSentWindow(value) {
      this.$store.commit('HelpWidget/showTicketSentWindow', value)
    },

    parseChatMessageResponse(txt) {
      // I will parse the message in format Markup or text and I will convert it to HTML
      return this.convertTextToHTML(this.convertMarkupToHtml(txt)).trimEnd()
    },

    convertMarkupToHtml(markup) {
      if (!markup || /^\s*$/.test(markup)) {
        return ''
      }

      // Convert headers
      markup = markup.replace(/^# (.*)$/gm, '<h1>$1</h1>')

      // Convert bold text (Markdown style, avoid HTML tags)
      markup = markup.replace(/\*\*(?![^<>]*?>)(.*?)\*\*/g, '<strong>$1</strong>')

      // Convert italic text (Markdown style, avoid HTML tags)
      markup = markup.replace(/\*(?![^<>]*?>)(.*?)\*/g, '<em>$1</em>')

      // Convert list items (avoid HTML tags)
      markup = markup.replace(/^- (.*)$/gm, '<li>$1</li>')

      // Wrap list items in <ul> if any <li> exists
      if (markup.includes('<li>')) {
        markup = markup.replace(/(<li>.*?<\/li>)/gs, '<ul>$1</ul>')
      }

      // Convert Markdown-style links (avoid interfering with HTML anchors)
      markup = markup.replace(/(?<!<a[^>]*>)\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>')

      // Define the regex pattern to match references like [doc1], [doc2], etc.
      markup = markup.replace(/\[doc\d+\]/g, '')

      return markup
    },

    convertTextToHTML(txt) {
      // Output variable
      let out = ''
      // Split text at the newline character into an array
      const txtArray = txt.split('\n')
      // Get the number of lines in the array
      const txtArrayLength = txtArray.length
      // Variable to keep track of the (non-blank) line number
      // const nonBlankLineCount = 0

      for (let i = 0; i < txtArrayLength; i++) {
        // Get the current line
        const line = txtArray[i]
        // Continue if a line contains no text characters
        if (line === '') {
          continue
        }

        // nonBlankLineCount++;
        /// / If a line is the first line that contains text
        // if (nonBlankLineCount === 1) {
        //    // ...wrap the line of text in a Heading 1 tag
        //    out += `<h1>${line}</h1>`;
        //    // ...otherwise, wrap the line of text in a Paragraph tag.
        // } else {
        out += `${line}`
        // }
      }

      return out
    }
  }
}
</script>
<style lang="scss" scoped>
div.chatAssistant p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
input {
  &:disabled {
    opacity: 0.5;
  }
}
.jmaglogo {
  background-image: url(https://magazine.imgix.net/chatbot-magazine-logo.png?auto=format);
  image-rendering: optimizequality;
  background-position-x: 0px;
  background-position-y: 2px;
}
.jmaglogoxs {
  background-repeat: no-repeat;
  width: 80px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
}
.fr {
  float: right;
}

.pmlogoxs {
  width: 80px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
}
.pmimg,
.fab {
  background-image: url(https://pocketmags.imgix.net/pocketmags-spritesv3.png?auto=format&q=50);
  image-rendering: optimizequality;
}
.hwbtn {
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  padding: 0px 10px 2px 10px;
  background-color: #07a09e;
  color: white;
  border-radius: 20px 5px 20px 20px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: transform 0.5s ease, width 0.5s ease;
  white-space: nowrap;
}
.hwico {
  background-size: 100px;
  background-position: 100px 350px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 5px;
  filter: brightness(100);
}
.help-text {
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  transition: opacity 0.5s ease, max-width 0.5s ease;
  white-space: nowrap;
  overflow: hidden;
}
.activetxt {
  opacity: 1;
  visibility: visible;
  max-width: 100px;
  padding-right: 5px;
}
.hwbtn:hover {
  transform: scale(1.05);
}
.hwbtn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 0;
}
.hwbtn.pulse::before {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}
.hwchatwindow {
  position: fixed;
  border-radius: 20px;
  bottom: 70px;
  right: 30px;
  width: 400px;
  height: 65vh;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0.5;
  transform: scale(0);
  transform-origin: bottom right;
  z-index: 998;
}
.hwchatwindowactive {
  opacity: 1;
  transform: scale(1);
}
.hwchatheader {
  background-color: #2e3b4b;
  background: linear-gradient(to right, #2e3b4b, #2c4d82);
  border-radius: 20px 20px 0px 0px;
  padding: 20px 25px;
  height: 150px;
  box-sizing: border-box;
}
.hwchatcontent {
  background-color: #f3f4f6;
  border-radius: 0px 0px 20px 20px;
  padding: 15px 10px;
  height: calc(100% - 150px);
  box-sizing: border-box;
  position: relative;
}
.hwdialogclose {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  float: right;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  color: black;
  font-size: 14px;
}
.hwdialogclose:hover {
  background-color: black;
  color: white;
}
.hwheadertxt {
  color: white;
  font-size: 17px;
  font-weight: 600;
  width: calc(100% - 140px);
  float: left;
  line-height: 40px;
}
.hwheaderpic {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px white solid;
  background-color: white;
  float: left;
  margin-right: 10px;
  background-image: url(https://magazine.imgix.net/chatbot-magazine-icon.png?auto=format);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 0px;
  background-position-y: -1px;
}
.hwchat {
  width: 100%;
  align-content: baseline;
  display: grid;
  background-color: white;
  font-size: 14px;
  line-height: 19px;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  top: -80px;
  padding: 15px 10px;
  box-sizing: border-box;
  height: calc(100% + 10px);
  overflow-y: auto;
}
.hwchattxtboxholder {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  top: -80px;
}
.hwchattxtboxsep {
  width: calc(100% - 20px);
  margin-left: 10px;
  border-top: 1px #e4e8ed solid;
}
.hwchattxtbox {
  font-size: 16px;
  padding: 15px 5px;
  border: none;
  color: #333;
  background-color: transparent;
  width: calc(100% - 85px);
}
.hwchattxtbox:focus {
  border: none;
  outline: none;
}
.hwchattxtbox:auto-fill {
  outline: 3px solid black;
}
.hwchattxtbox:-webkit-autofill {
  outline: 3px solid black;
}
.hwlogo {
  position: relative;
  top: -72px;
  float: right;
}
.hwfootertxt {
  font-size: 10px;
  margin-right: 5px;
  line-height: 20px;
}
.hwchatsendbtn {
  &--disabled {
    opacity: 0.5;
  }
  height: 40px;
  line-height: 40px;
  width: 75px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  border: none;
  background-color: #07a09e;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
  padding: 0px 10px;
  float: right;
  color: white;
  font-family: 'Inter', arial, sans-serif;
  font-size: 13.3px;
}
.chatUser {
  width: fit-content;
  height: fit-content;
  justify-self: end;
  background-color: #079397;
  color: white;
  border-radius: 15px 4px 15px 15px;
  position: relative;
  padding: 10px 15px;
  box-sizing: border-box;
  margin: 30px 5px 10px 30px;
}
.chatAssistant {
  width: fit-content;
  height: fit-content;
  justify-self: start;
  background-color: #f0f2f7;
  border-radius: 4px 15px 15px 15px;
  position: relative;
  padding: 10px 15px;
  box-sizing: border-box;
  margin: 30px 30px 10px 5px;
}
.chatError {
  width: fit-content;
  height: fit-content;
  justify-self: start;
  background-color: #ff0d004a;
  border-radius: 4px 15px 15px 15px;
  position: relative;
  padding: 10px 15px;
  box-sizing: border-box;
  margin: 30px 30px 10px 5px;
}
.chatAssistant::before {
  content: 'Magazine.co.uk Support';
  left: 0;
}
.chatAssistant::before,
.chatUser::before {
  position: absolute;
  top: -22px;
  font-size: 11px;
  color: #5c5c5c;
}

.chatUser::before {
  content: 'You';
  right: 0;
}

.chatticketholder,
.chatticketholdersent {
  width: 100%;
  background-color: white;
  font-size: 14px;
  line-height: 19px;
  border-radius: 10px;
  position: relative;
  top: -80px;
  padding: 20px 15px;
  box-sizing: border-box;
  height: calc(100% + 60px);
  overflow-y: auto;
}
.hwmessage {
  height: calc(65vh - 520px);
  min-height: 100px;
  padding: 10px;
}
.hwtcbtn {
  width: auto;
  float: unset;
  display: inline-block;
  margin: 20px 0 0 0;
}
.feedback-container {
  min-height: 80px;
  margin: 5px 30px 10px 5px;
  font-size: 14px;
  padding: 10px;
  background-color: #f0f2f7;
  border-radius: 10px;
}
.feedback-question {
  margin-bottom: 10px;
  font-weight: bold;
}
.feedback-options {
  display: flex;
  justify-content: space-between;
}

.feedback-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}

.feedback-button.yes {
  background-color: transparent;
  border: 1px #07a09e solid;
  color: #07a09e;
}
.feedback-button.no {
  background-color: transparent;
  border: 1px #07a09e solid;
  color: #07a09e;
}
.feedback-button.open-ticket {
  background-color: #07a09e;
  border: 1px #07a09e solid;
}
.hwchat p {
  padding: 5px 0px;
  margin: 0;
}
.hwchat a {
  color: #079397;
}
.hwinput-error {
  border: 1px solid red;
  background-color: #fef2f2;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: calc(50% - 15px);
  transform: translate(-50%, -50%);
  display: none;
  font-size: 16px;
  color: #007bff;
  display: block;
  z-index: 1000;
}
.loading-indicator.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.shimmer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: linear-gradient(90deg, #f5f5f5 25%, #e0e0e0 50%, #f5f5f5 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

/* Temporary box with dots */
.temp-box {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 23px 10px 17px 10px;
  box-sizing: border-box;
  margin: 5px 40px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp-box .dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp-box .dot {
  background-color: #5c607c;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  animation: dot-blink 1.5s infinite ease-in-out, dot-bounce 1.5s infinite ease-in-out;
}
.temp-box .dot:nth-child(2) {
  animation-delay: 0.2s, 0.2s; /* Adjust delay for bounce animation */
}
.temp-box .dot:nth-child(3) {
  animation-delay: 0.4s, 0.4s; /* Adjust delay for bounce animation */
}

@keyframes dot-blink {
  0%,
  20%,
  80%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

@keyframes dot-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px); /* Adjust vertical distance */
  }
}

.hwchatsendbtn .spinner {
  position: absolute;
  right: calc(50% - 11px);
  top: calc(50% - 11px);
  transform: translateY(-50%);
  width: 23px;
  height: 23px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: rgba(255, 255, 255, 0.5);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hwdialogreset {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  float: right;
  padding: 5px;
  margin-right: 20px;
}
.reset-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}
.reset-icon:hover {
  transform: rotate(20deg);
  stroke: #333;
}

@media (max-width: 768px) {
  .hwbtn {
    right: 20px;
  }
  .hwchatwindow {
    right: 20px;
    width: calc(100% - 40px);
    z-index: 10001;
  }
  .hwheadertxt {
    line-height: 20px !important;
  }
}

// OPEN TICKET
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.w100 {
  width: 100%;
}

.rad5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.textboxlarge {
  height: 47px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  border: solid 1px #b3b3b3;
  padding: 4px 10px;
  font-size: 16px;
  background-color: #fff;
  outline: none;
  color: #474747;
}

.checkbox input[type='checkbox']:checked + label::before {
  background-color: #079f9f;
  content: '';
  background-image: url(https://pocketmags.imgix.net/pocketmags-spritesv3.png?auto=format&q=50);
  image-rendering: optimizequality;
  background-size: 320%;
  background-position: 33px -702px;
  border: 1px solid #079f9f;
}

.checkbox input[type='checkbox'] + label::before {
  background-color: transparent;
  content: '';
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #e1e1e1;
  height: 22px;
  width: 22px;
  padding: 0;
  margin: 0;
  position: static;
  transition: background-color 250ms;
}

.checkbox label {
  display: inline-block;
  padding: 0;
  margin-right: 0;
  height: 25px;
  cursor: pointer;
  top: 5px;
  position: relative;
}

.checkbox label:before,
.bigCheck label:before,
.smallCheck label {
  content: none;
}
</style>
